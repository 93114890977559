import ApiResource from '@/core/http/ApiResource';

export type UserType = 'MASTER' | 'PARTNER' | 'LAW_FIRM' | 'CUSTOMER';

interface UserAssociatedEntityStub {
	id: number;
	name: string | undefined;
}

export interface User {
	id: number;
	name: string;
	email: string;
	created_time: string;
	type: UserType,
	permissions: string[] | undefined;
	language: string,
	partner: UserAssociatedEntityStub,
	law_firm: UserAssociatedEntityStub,
	customer: UserAssociatedEntityStub,
}

export interface UserUpdate extends User {
	password: string | undefined;
}

export class UserResource extends ApiResource<User> {
	putPermissions(userId: number, data: string[]): Promise<User> {
		return this.getApi(true).put(`/api/user/${userId}/permissions`, data);
	}
}

export const useUser = () => new UserResource('/api/user');
