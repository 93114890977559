import Column from '@/core/components/resource/Column';
import { RouteLocationRaw } from 'vue-router';
import { LinkBuilder } from '@/core/common';
import auth from '@/core/Auth';

type ButtonActionCallback<T> = (row: T) => void;

interface ActionItemConfig<T> {
	type: 'link' | 'button';
	callback?: ButtonActionCallback<T>;
	icon?: string;
	iconWeight?: string;
	className?: string;
	builder?: LinkBuilder<T>;
	permissions?: string[];
}

class ActionItem<T> {
	public readonly config: ActionItemConfig<T>;

	constructor(config: ActionItemConfig<T>) {
		this.config = config;
	}

	public getClass(): string {
		return this.config.className || '';
	}

	public isLink(): boolean {
		return this.config.type === 'link';
	}

	public trigger(row: T): void {
		if (!this.config.callback) {
			throw new Error('Action column button item missing callback');
		}

		return this.config.callback(row);
	}

	public to(row: T): RouteLocationRaw {
		if (!this.config.builder) {
			throw new Error('Action column link item missing route builder');
		}

		return this.config.builder(row);
	}

	public iconWeight(): string {
		return this.config.iconWeight || 'r';
	}

	public hasPermission(): boolean {
		if (this.config.permissions) {
			return auth.canAll(this.config.permissions);
		}

		return true;
	}
}

export default class ActionColumn<T> extends Column<T> {
	public isLink = false;
	public to: LinkBuilder<T> | null = null;
	public callback: ButtonActionCallback<T> | null = null;
	public items: Array<ActionItem<T>> = [];

	constructor(label = '') {
		super(label, 'id' as keyof T);
	}

	protected configure() {
		this.component = 'ActionColumn';
		this.classList.push('action-column');
	}

	public filterItems() {
		this.items = this.items.filter(i => i.hasPermission());
	}

	public addWidthClass() {
		this.width = `${this.items.length * 22}px`;
	}

	public withItem(config: ActionItemConfig<T>): ActionColumn<T> {
		this.items.push(new ActionItem(config));

		return this;
	}

	public withButton(icon: string, className: string, callback: ButtonActionCallback<T>, permissions: string[] = []): ActionColumn<T> {
		return this.withItem({ type: 'button', icon, className, callback, permissions });
	}

	public withLink(icon: string, className: string, builder: LinkBuilder<T>, permissions: string[] = []): ActionColumn<T> {
		return this.withItem({ type: 'link', icon, className, builder, permissions });
	}

	public withEditLink(builder: LinkBuilder<T>, permissions: string[] = []): ActionColumn<T> {
		return this.withItem({
			type: 'link',
			iconWeight: 's',
			icon: 'pencil-alt',
			className: 't-warning',
			builder,
			permissions,
		});
	}

	public withDeleteLink(callback: ButtonActionCallback<T>, permissions: string[] = []): ActionColumn<T> {
		return this.withItem({
			type: 'button',
			iconWeight: 's',
			icon: 'trash-alt',
			className: 't-danger',
			callback,
			permissions,
		});
	}
}
