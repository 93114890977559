import store from '@/store';
import ApiResource from '@/core/http/ApiResource';

export type Permission = Record<string, string>;

const resource = new ApiResource<Permission>('/api/parameters/permissions');

export const usePermission = async (forType: string): Promise<Permission[]> => {
	if (typeof store.state.permissions[forType] !== 'undefined') {
		return store.state.permissions[forType] as Permission[];
	}

	const response = await resource.request<Permission[]>('GET', forType);

	store.commit('setPermissions', {
		type: forType,
		collection: response,
	});

	return response;
};
