import store from '@/store';
import { Api } from '@/core/http/Api';

export interface Language {
	id: string;
	name: string;
	created_time: string;
}

const api = new Api();

export const getAllLanguages = async (): Promise<Language[]> => {
	if (typeof store.state.languages !== 'undefined') {
		return store.state.languages;
	}

	const response = await api.get<Language[]>('/api/parameters/language');

	store.commit('setLanguages', response);

	return response;
}
